const BtnColaboremos = () => {
  return (
    <button
        className="bg-[#E92F72] text-center fkex items-center
        w-[175px] py-[8px] rounded-[8px]">
        <p className="textbutton text-white">
          COLABOREMOS
        </p>
    </button>
  )
}

export default BtnColaboremos
