const BtnSiguenos = () => {
    return (
        <button
            className="flex items-center justify-center bg-negroV rounded-[8px]
            w-[309px] h-[56px] sm:w-[352px] sm:h-[64px] hover:shadow-black hover:shadow-md">
            <p className="font-[Jost] font-bold text-white
            text-[16px] sm:text-[18px] uppercase">
                Síguenos en instagram
            </p>
        </button>
    )
}

export default BtnSiguenos
