import { linkB2C } from '../global/var'
import BtnCotiza from './buttons/BtnCotiza'
import './styles/styleAux.css'

const Footer = () => {

  return (
    <footer
      className="h-auto z-0 bg-cover bg-center text-white
      bg-[url('/public/imagenes/bg-footer.jpg')]">
      <div className='mx-auto
          w-[312px] pt-[48px]
          sm:w-full sm:pt-[64px] '>
        <h2 className='text-amarilloV'> CONTÁCTANOS</h2>
        <p
          className="mx-auto textP text-center text-white
          pt-[48px] pb-[24px]  
          sm:py-[32px] sm:w-[467px]">
          Para mayor información puedes escribirnos a nuestro whatsApp o seguirnos en nuestras redes sociales
        </p>
        <div className='flex justify-center items-center gap-[20px] pb-[32px] sm:pb-[40px]'>
          <a
            href="https://www.linkedin.com/company/vertices-graffiti/"
            target="_blank"
            className='hover:opacity-80'>
            <img src='/svg/lk.svg' alt='linkedin' />
          </a>
          <a
            href="https://www.instagram.com/vertices.pro/"
            target="_blank"
            className='hover:opacity-80'>
            <img src='/svg/ig.svg' alt='Instagram' />
          </a>
          <a
            href="https://www.facebook.com/vertices.pe"
            target="_blank"
            className='hover:opacity-80'>
            <img src='/svg/fb.svg' alt='Facebook' />
          </a>
        </div>
        <div className='flex justify-center items-center gap-[8px] sm:gap-[15px] pb-[40px]'>
          <img src='/svg/correo.svg' alt='Correo' />
          <p className='font-[Jost] font-medium text-[20px] sm:text-[24px]'> vertices.pro@gmail.com </p>
        </div>
        <div className='flex justify-center items-center gap-[8px] sm:gap-[15px] pb-[48px]'>
          <img src='/svg/llamada.svg' alt='LLamada' />
          <p className='font-[Jost] font-medium text-[20px] sm:text-[24px]'> +51 991231559 </p>
        </div>
        <div className='flex justify-center'>
          <a
            href={linkB2C}
            target="_blank">
            <BtnCotiza bg="#FFDC4D" color="#232323" />
          </a>
        </div>
        <p className='font-[Jost] font-medium text-center pb-[24px] pt-[72px] sm:pb-[64px] sm:pt-[109px]'>
          © Copyright 2022 VÉRTICES | All Rights Reserved | Powered by
          <a
            href="https://www.ciclos.studio/"
            target="_blank">
            <span className=' font-bold text-verdeV'> CICLOS STUDIO </span>
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer