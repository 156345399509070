import SwiperHeroB2B from "./swipers/SwiperHeroB2B"
import CardPropuesta from "./cards/CardPropuesta"
import SwiperOpiniones from "./swipers/SwiperOpiniones"
import Logos from "./OrganizacionesB2B/Logos"
import { Link } from "react-scroll"
import FormContactanos from "./forms/FormContactanos"

const HomeB2B = () => {

   return (
      <div className="">
         <section id="HeroB2B"
            className="bg-[url('/public/imagenes/HeroB2B.jpg')] 
            bg-center min-h-[100vh] sm:h-auto flex items-center">
            <div
               className=" grid gap-[35px] w-[312px] mx-auto
               pb-[180px] pt-[160px] 
               sm:pb-[180px] sm:pt-[172px] sm:w-auto
                lg:grid-cols-[1.5fr_1fr] sm:mx-[8%] 
                xl:grid-cols-[686px_459px] xl:max-w-[1180px] xl:mx-auto">
               <div
                  className="self-center order-2 lg:order-1">
                  <div
                     className="font-[Jost] font-medium text-white
                     text-[27px] leading-[40px] text-center
                     sm:leading-[70px] sm:px-0 
                     mdAux:px-[5%]  
                     lg:text-[54px] lg:leading-[60px] lg:text-left lg:px-0 
                     xl:leading-[70px]  xl:text-[64px]">
                     El talento y la disciplina como {" "}
                     <span className="text-[#FFDC4D] font-black ">cambio social</span>
                  </div>
                  <p
                     className="font-Nunito font-bold  text-white
                      pt-[24px] pb-[40px] text-[14px] text-center leading-[28px]
                      sm:text-[24px] sm:text-center sm:leading-[32px]
                      lg:text-[20px] lg:text-left lg:leading-[28px]
                      xl:pt-[20px] xl:pb-[54px] xl:text-[24px] xl:leading-[32px] ">
                     Somos vértices y recuperamos espacios públicos a través del graffiti
                     y el arte urbano en diversas zonas de Lima, fomentando valores cívicos en la ciudadanía.
                  </p>
               </div>
               <div
                  className="order-1 lg:order-2">
                  <SwiperHeroB2B />
               </div>
            </div>
         </section>

         <section id="Propuesta">
            <div
               className="mx-auto w-[312px] py-[48px] 
               sm:w-full sm:px-[8%] sm:py-[64px]
               xl:w-[1016px] xl:px-0">
               <h2 className="text-rosaV"> NUESTRA PROPUESTA </h2>
               <p className="textP text-center pt-[24px] pb-[18px] sm:pb-[32px]">
                  “Transformar los problemas sociales en oportunidades”
               </p>
               <div
                  className="grid mx-auto gap-[40px]
                  sm:grid-cols-2 lg:grid-cols-3">
                  <CardPropuesta title="Recuperación de espacios públicos"
                     text="Contaminación visual y política en espacios públicos"
                     img="/imagenes/homeB2B/propuestas/propuesta2.jpg" />
                  <CardPropuesta title="Talleres de arte para niños y adolescentes"
                     text="Delincuencia urbana y violencia juvenil en las calles"
                     img="/imagenes/homeB2B/propuestas/propuesta1.jpg" />
                  <CardPropuesta title="Intervenciones y murales participativos"
                     text="Falta de espacios de intervención y promoción cultural"
                     img="/imagenes/homeB2B/propuestas/propuesta3.jpg" />
               </div>
            </div>
         </section>

         <section id="Motivacion">
            <div
               className="mx-auto w-[312px] pb-[32px] 
               sm:w-full sm:px-[8%] sm:pb-[64px] sm:pt-[32px]
               lg:w-[830px] lg:px-0">
               <h2 className="text-rosaV"> ¿Qué nos motiva? </h2>
               <p
                  className="pB2B text-center mx-auto 
                  pt-[12px] pb-[44px]
                  sm:py-[32px] sm:w-[556px]">
                  Trabajamos con un enfoque social y creemos en el arte como una vía para lograr objetivos profesionales, personales y sociales.
               </p>
               <div
                  className="grid items-center gap-[24px]
                  md:grid-cols-[264px_1fr] md:gap-[27px]">
                  <img
                     src="/imagenes/homeB2B/img-motiva.png"
                     alt="Educación de calidad"
                     className="mx-auto md:w-full">
                  </img>
                  <div>
                     <p className="font-[Poppins] font-medium text-negroV
                      text-center sm:text-left text-[18px] sm:text-[28px]">
                        Educación de calidad
                     </p>
                     <p className="pB2B pt-[12px] text-center sm:text-left">
                        El principal foco de Vértices es la enseñanza, un graffitero o artista urbano inicia aprendiendo del otro, del mentor y de ese modo nos alineamos con la ODS 4: Educación de calidad.
                     </p>
                     <p className="pB2B text-center sm:text-left">
                        La educación no es ajena a nuestro proceso, por ello brindamos talleres gratuitos en diversos  espacios con las organizaciones que nos apoyan.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <section id="Opinion"
            className="bg-[url('/public/imagenes/homeB2B/bg-1.png')] relative bg-center
            before:w-full before:h-full before:absolute before:top-0 before:bg-black/[0.7]
            py-[48px] sm:py-[72px]">
            <SwiperOpiniones />
         </section>

         <section id="Organizaciones"
            className="bg-white py-[48px] sm:py-[36px] text-center">
            <h2 className="text-negroV w-[312px] mx-auto text-center sm:w-auto">
               organizaciones
            </h2>
            <p
               className="w-[312px] mt-[8px] mx-auto
               sm:w-auto sm:mx-[8%] 
               lg:mx-auto lg:max-w-[719px] ">
               Colaboramos con organizaciones, empresas, emprendimientos sociales
               y gestores culturales de diversas zonas de Lima, el interior del Perú y Latinoamérica.
            </p>
            <Logos />
         </section >

         <section id="Iniciativa"
            className="bg-white text-center py-[24px] sm:py-[64px]">
            <h2 className="text-rosaV w-[312px] mx-auto text-center sm:w-auto">
               COLABORA CON NOSOTROS
            </h2>
            <p
               className="w-[312px] mt-[8px] mx-auto
               sm:w-auto sm:mx-[8%] 
               lg:mx-auto lg:max-w-[719px] ">
               Participamos de Actividades con Enfoque Social y ProBono,
               si eres una ONG o una Organización Civil escríbenos
            </p>
            <div
               className="w-[312px] mx-auto gap-y-[48px]
               grid  justify-items-center mt-[32px] 
               sm:max-w-[1139px] sm:w-auto  sm:px-[2%] 
               md:grid-cols-2 md:gap-y-[60px] md:mt-[78px]
               lg:grid-cols-3 xl:px-0">
               <img
                  src="/imagenes/homeB2B/iniciativas/iniciativa1.png"
                  alt="Primera iniciativa"
                  className=" lg:w-[300px] xl:w-[339px] "
               />
               <img
                  src="/imagenes/homeB2B/iniciativas/iniciativa2.png"
                  alt="Segunda iniciativa"
                  className=" lg:w-[300px] xl:w-[339px]"
               />
               <img
                  src="/imagenes/homeB2B/iniciativas/iniciativa3.png"
                  alt=" tercera iniciativa"
                  className="md:col-span-2 
                  lg:col-span-1 lg:w-[300px] xl:w-[339px]"
               />
            </div>
            <div
               className="text-center font-Nunito font-[400]
               mx-auto mt-[40px] w-[312px]
               text-[20px] leading-[44px]
               sm:mx-[10%] sm:text-[36px] sm:leading-[50px] sm:w-auto
               md:text-[36px] md:leading-[50px] md:mt-[66px]  
               lg:mx-auto lg:max-w-[934px]">
               “Buscamos transformar la sociedad y espacios donde el arte
               urbano y la ciudadanía convivan y generen oportunidades para todos”
            </div>
         </section>

         <section id="Contactanos"
            className="grid items-center lg:grid-cols-[1fr_1fr] bg-cover bg-center
            bg-[url('/public/imagenes/homeB2B/footerBg.jpg')]">
            <div
               className=" w-[328px] 
               justify-self-center py-[72px] 
               lg:py-0
               xl:mr-[196px] xl:justify-self-end">
               <div
                  className="">
                  <Link
                     to="Hero"
                     className="flex justify-center cursor-pointer">
                     <img
                        src="/svg/logopink.svg"
                        alt="Logo"
                        className="w-[176px] sm:w-[264px]"
                     />
                  </Link>
                  <div
                     className='flex justify-center items-center gap-[8px] pt-[40px] pb-[24px] 
                      sm:gap-[15px]sm:py-[48px]'>
                     <img src='/svg/correo.svg' alt='Correo' />
                     <p
                        className='text-white font-[Jost] font-medium text-[20px] sm:text-[24px]'>
                        vertices.pro@gmail.com
                     </p>
                  </div>

                  <div className='flex justify-center items-center gap-[8px] sm:gap-[15px]'>
                     <img src='/svg/llamada.svg' alt='LLamada' />
                     <p className='text-white font-[Jost] font-medium text-[20px] sm:text-[24px]'> +51 991231559 </p>
                  </div>

                  <p
                     className="pB2B text-white text-center
                      pt-[40px] pb-[20px] sm:pt-[64px] sm:pb-[40px]">
                     Síguenos
                  </p>
                  <div className='flex justify-center items-center gap-[20px]'>
                     <a
                        href="https://www.linkedin.com/company/vertices-graffiti/"
                        target="_blank"
                        className="hover:opacity-80">
                        <img src='/svg/lk.svg' alt='linkedin' />
                     </a>
                     <a
                        href="https://www.instagram.com/vertices.pro/"
                        target="_blank"
                        className="hover:opacity-80">
                        <img src='/svg/ig.svg' alt='Instagram' />
                     </a>
                     <a
                        href="https://www.facebook.com/vertices.pe"
                        target="_blank"
                        className="hover:opacity-80">
                        <img src='/svg/fb.svg' alt='Facebook' />
                     </a>
                  </div>
               </div>
            </div>

            <div
               className="bg-white ">
               <FormContactanos />
            </div>
         </section>
      </div>
   )
}

export default HomeB2B
