const CardValores = (props) => {
   return (
      <div
         className="w-[160px] mx-auto
        sm:w-full">
         <img
            src={props.icon}
            alt={props.p}
            className="mx-auto
            w-[160px] sm:w-[176px]">
         </img>
         <p
            style={{color: props.color}}
            className="font-[Jost] font-medium 
            text-center text-[22px]
            pt-[8px] pb-[1px] sm:py-[16px]">
            {props.p}
         </p>
         <img
            src={props.mancha}
            alt={props.p}
            className="mx-auto">
         </img>
      </div>
   )
}

export default CardValores
