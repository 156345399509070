import React from 'react'

const CardOpinion = (props) => {
    return (
        <div
            className='text-white text-center 
            pb-[38px] max-w-[312px] mx-auto 
            md:mx-auto md:max-w-[621px]'>
            <small
                className='font-[400] italic
                text-[12px] leading-[24px]
                sm:text-[18px] sm:leading-[30px] '>
                {props.text}
            </small>
            <div
                className='font-[700] text-[#7BF8BF] block
                text-[12px] leading-[24px] mt-[15px] 
                sm:text-[18px] sm:leading-[32px] '>
                {props.autor}
            </div>
            <p
                className='font-[700]
                text-[16px] leading-[24px] mt-[7px]
                sm:text-[20px] sm:leading-[30px]'>
                {props.lugar}
            </p>
        </div>
    )
}

export default CardOpinion