import { useMediaQuery } from 'react-responsive'
import { useState } from "react"
import { Link } from "react-scroll"
import { AnimatePresence, motion } from "framer-motion"
import BtnColaboremos from "./buttons/BtnColaboremos"
import { Link as LinkRouter } from "react-router-dom"
import { linkB2B } from '../global/var'

const NavBarB2B = () => {

   const isLg = useMediaQuery({ query: '(min-width: 1110px)' })
   const [menu, setMenu] = useState(false)

   return (
      <header
         className="flex justify-items-center justify-center bg-negroV z-20
         w-full py-[12px] h-[64px] sm:py-[16px] sm:h-[80px] fixed top-0
         shadow-negroV shadow-sm">
         <nav
            className="flex justify-between
            w-[312px]
            sm:w-full sm:px-[8%] 
            xl:w-[1180px] xl:px-0">
            <LinkRouter
               to="/"
               spy={true}
               offset={-64}
               smooth={true}
               duration={500}
               className="cursor-pointer">
               <img
                  src="/svg/logopink.svg"
                  alt="Logo"
                  className="w-[144px] sm:w-auto"
               />
            </LinkRouter>
            {isLg ?
               <div className="flex items-center gap-[80px] scale-75  lgAux:scale-100">
                  <LinkRouter
                     to="/"
                     spy={true}
                     smooth={true}
                     offset={-64}
                     duration={800}>
                     <p className="text-white cursor-pointer font-Nunito font-bold text-[16px]">
                        Servicios de Vértices
                     </p>
                  </LinkRouter>
                  <a
                     href={linkB2B}
                     target="_blank">
                     <BtnColaboremos />
                  </a>
               </div>
               :
               <img src="/svg/burguer.svg"
                  alt="burger"
                  className='cursor-pointer'
                  onClick={() => setMenu(!menu)}>
               </img>
            }
         </nav>

         <AnimatePresence>
            {menu && !isLg &&
               <motion.div
                  key="navbar movil"
                  initial={{ opacity: 0, x: 150 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, x: 150 }}
                  className="bg-negro absolute right-0 z-20
                   w-full px-[24px] top-[64px] bg-negroV
                   sm:top-[80px]">
                  <LinkRouter
                     to="/"
                     spy={true}
                     offset={-64}
                     smooth={true}
                     duration={800}
                     onClick={() => setMenu(!menu)}>
                     <div className="pNav text-center py-[24px]">
                        Servicios de Vértices
                     </div>
                  </LinkRouter>
                  <div className="flex  justify-center py-[24px]">
                     <a
                        href={linkB2B}
                        target="_blank">
                        <BtnColaboremos />
                     </a>
                  </div>
               </motion.div>
            }
         </AnimatePresence>
      </header>
   )
}

export default NavBarB2B
