import React from 'react'

const CardPersonaliza = (props) => {
   return (
      <div
         style={{ borderColor: props.bg }}
         className='bg-white p-[16px] rounded-b-[20px] border-t-[10px]
         mx-auto w-[240px] sm:w-full'>
         <div
            style={{ background: props.bg, color: props.color }}
            className='bg-rosaV rounded-full mx-auto
            flex justify-center items-center
            w-[56px] h-[56px] sm:w-[64px] sm:h-[64px]
            font-[Poppins] font-semibold text-[24px]'>
            {props.number}
         </div>
         <p className='text-center text-[16px] sm:text-[20px] 
            font-[Jost] font-semibold  text-negroV pt-[16px] pb-[8px] '>
            {props.sub}
         </p>
         <p className='textP text-center'>
            {props.text}
            <a
               href="https://api.whatsapp.com/send?phone=+51961430490&text=Hola,%20somos%20V%C3%A9rtices.%20%C2%BFCu%C3%A9ntanos%20c%C3%B3mo%20podemos%20ayudarte"
               target="_blank">
               {" "}
               <span className="font-bold text-rosaV underline cursor-pointer">
                  {props.a}
               </span>
            </a>
         </p>
      </div>
   )
}

export default CardPersonaliza
